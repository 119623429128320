import React from 'react';
import { Row, Col, Form, Select, Button, Table } from 'antd';

import { Card } from 'src/components';
import { table } from 'src/utils';

import { rules } from '../../new.utils';
import { IProps } from './variant.type';
import { columns } from './variant.utils';

import useProduct from './variant.use';

const VariantComponent: React.FC<IProps> = (props) => {
  const form = Form.useFormInstance();

  const { colors, subGroups } = props;
  const { dataSource, handleAdd, handleDelete } = useProduct(props);

  return (
    <Card title="Variantes">
      <Row gutter={16} align="middle">
        <Col md={8} sm={24} xs={24}>
          <Form.Item label="Cor" name="colorId" rules={rules.colorId}>
            <Select allowClear placeholder="Selecione...">
              {colors?.map((item: any) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label="Subgrupo"
            name="subGroupId"
            rules={rules.subGroupId}
          >
            <Select allowClear placeholder="Selecione...">
              {subGroups?.map((item: any) => (
                <Select.Option
                  key={item.value}
                  value={item.value}
                  title={item.label}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Button
            type="primary"
            className="mt-1"
            onClick={() =>
              handleAdd({
                colorId: form.getFieldValue('colorId'),
                subGroupId: form.getFieldValue('subGroupId'),
              })
            }
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} className="mt-3">
          <Table
            {...table}
            dataSource={dataSource}
            columns={columns(handleDelete)}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default VariantComponent;
