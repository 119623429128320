import React from 'react';
import { Button } from 'antd';

import { Card } from 'src/components';

import { IProps } from './form-finish.type';

const FormFinish: React.FC<IProps> = (props) => {
  const { formID, sending } = props;

  return (
    <Card>
      <Button loading={sending} htmlType="submit" form={formID} type="primary">
        Salvar
      </Button>
    </Card>
  );
};

export default FormFinish;
