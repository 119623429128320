import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import InputMask from 'react-input-mask';

import { Card } from 'src/components';
import states from 'src/utils/constants/states';

import { rules } from '../../edit.utils';

const AddressComponent: React.FC<{}> = () => {
  return (
    <Card title="Endereço">
      <Row gutter={16}>
        <Col md={8} sm={24} xs={24}>
          <Form.Item label="CEP" name="postalCode" rules={rules.postalCode}>
            <InputMask mask="99999-999">
              {(inputProps: any) => <Input placeholder="CEP" {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item label="Cidade" name="city" rules={rules.city}>
            <Input placeholder="Cidade" />
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item label="Estado" name="state" rules={rules.state}>
            <Select placeholder="Selecione...">
              {states.map((item, index) => (
                <Select.Option value={item.label} key={index}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={16} sm={24} xs={24}>
          <Form.Item label="Endereço" name="address" rules={rules.address}>
            <Input placeholder="Endereço" />
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label="Número"
            name="addressNumber"
            rules={rules.addressNumber}
          >
            <Input placeholder="Número" />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            label="Bairro"
            name="neighborhood"
            rules={rules.neighborhood}
          >
            <Input placeholder="Bairro" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default AddressComponent;
