import { Popconfirm, Tooltip, Typography } from 'antd';
import { BsTrashFill } from 'react-icons/bs';

export const columns = (handleDelete: any) =>
  [
    {
      title: 'Cor',
      dataIndex: ['color', 'label'],
      key: 'color',
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Subgrupo',
      dataIndex: ['subGroup', 'label'],
      key: 'subGroup',
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text: any, record: any) => (
        <span>
          <Tooltip title="Remover">
            <Popconfirm
              style={{ minWidth: 120 }}
              title="Tem certeza?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Typography.Link style={{ marginLeft: 8 }}>
                <BsTrashFill size={18} />
              </Typography.Link>
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ] as any;
