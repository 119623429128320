import React from 'react';
import { Row, Col, Table, Skeleton, Form } from 'antd';
import { connect } from 'react-redux';

import { Card, Container, Title, GoBack } from 'src/components';
import { table } from 'src/utils';
import { IReduxState } from 'src/store';
import {
  fetchSubGroups,
  removeSubGroup,
  updateSubGroup,
} from 'src/store/sub-groups/actions';

import EditableCell from './components/cell/cell.component';
import Pagination from './components/pagination/pagination.component';

import { IProps } from './sub-groups.type';
import { columns } from './sub-groups.utils';
import useSubGroups from './sub-groups.use';

const SubGroupsScreen: React.FC<IProps> = (props) => {
  useSubGroups(props);

  const { subGroups } = props;

  const {
    loading,
    data,
    form,
    handleSubGroups,
    editingKey,
    isEditing,
    handleEdit,
    handleSave,
    handleCancel,
    handleRemove,
  } = useSubGroups(props);

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Configurações"
            title="Subgrupos"
            subtitle="Visualize abaixo a lista de subgrupos cadastrados no sistema."
          />
        </Col>
      </Row>
      {!loading ? (
        <Row gutter={16}>
          <Col span={24}>
            <Card>
              <Form form={form} component={false}>
                <Table
                  {...table}
                  bordered
                  components={components}
                  dataSource={data}
                  rowKey={(record: any) => record.id}
                  columns={columns({
                    editingKey,
                    isEditing,
                    handleEdit,
                    handleSave,
                    handleCancel,
                    handleRemove,
                  })}
                  rowClassName="editable-row"
                  pagination={false}
                />
              </Form>
            </Card>
          </Col>
          <Col span={24}>
            <Pagination
              pagesMeta={subGroups?.meta}
              handleSearch={handleSubGroups}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <Card>
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  subGroups: state.subGroups.all,
});

export default connect(mapStateToProps, {
  fetchSubGroups,
  removeSubGroup,
  updateSubGroup,
})(SubGroupsScreen);
