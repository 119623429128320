import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input } from 'antd';

import { Container, Card, Title, GoBack, FormFinish } from 'src/components';
import { createGroup } from 'src/store/groups/actions';

import { IProps } from './new.type';
import { rules } from './new.utils';
import useGroups from './new.use';

const NewScreen: React.FC<IProps> = (props) => {
  const { sending, handleSubmit } = useGroups(props);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Configurações" title="Cadastre um novo grupo" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Card title="Informações">
              <Row gutter={16}>
                <Col span={24} lg={12}>
                  <Form.Item label="Descrição" name="name" rules={rules.name}>
                    <Input placeholder="Descrição" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <FormFinish sending={sending} />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { createGroup })(NewScreen);
