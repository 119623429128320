import React, { useState } from 'react';
import { Row, Col, Form, Input } from 'antd';
import InputMask from 'react-input-mask';

import { Card } from 'src/components';

import { rules } from '../../new.utils';

const ContactComponent: React.FC<{}> = () => {
  const [mask, setMask] = useState('(99) 99999-9999');

  return (
    <Card title="Contato">
      <Row gutter={16}>
        <Col span={24} lg={8}>
          <Form.Item label="E-mail" name="email" rules={rules.email}>
            <Input placeholder="E-mail" />
          </Form.Item>
        </Col>
        <Col span={24} lg={8}>
          <Form.Item
            label="Telefone"
            name="phoneNumber"
            rules={rules.phoneNumber}
          >
            <InputMask
              mask={mask}
              onBlur={(e) => {
                if (e.target.value.replace('_', '').length === 14) {
                  setMask('(99) 9999-9999');
                }
              }}
              onFocus={(e) => {
                if (e.target.value.replace('_', '').length === 14) {
                  setMask('(99) 99999-9999');
                }
              }}
            >
              {(inputProps: any) => (
                <Input
                  {...inputProps}
                  type="tel"
                  placeholder="Telefone/Celular"
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default ContactComponent;
