import { Tooltip, Typography, Popconfirm, Popover, Image, Tag } from 'antd';
import moment from 'moment';
import { AiTwotoneEdit, AiTwotoneEye } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';
import { IFamily } from 'src/interfaces/families';
import { IGroup } from 'src/interfaces/groups';
import { ProductUnit } from 'src/interfaces/products';

import { unitTranslation } from 'src/utils/constants/unit';

export const columns = (handleRemove: any) =>
  [
    {
      title: 'Imagem',
      dataIndex: 'image',
      key: 'image',
      width: 50,
      render: (text: any) => (
        <span>
          <Popover
            style={{ minWidth: 150, maxWidth: 150 }}
            content={<Image width={120} src={text} fallback="" />}
          >
            <Typography.Link>
              <BsFillImageFill size={18} />
            </Typography.Link>
          </Popover>
        </span>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      shouldCellUpdate: (record: any, prevRecord: any) => true,
      render: (text: ProductUnit, record: any) => (
        <>
          <p className="mb-0">{text} </p>
          <small>
            <b>NCM: </b>
            {record.ncm}
          </small>
        </>
      ),
    },
    {
      title: 'Un.',
      dataIndex: 'unit',
      key: 'unit',
      width: 50,
      render: (text: ProductUnit) => (
        <Tag className="m-0">
          <b>{unitTranslation[text].split('-')[0]}</b>
        </Tag>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Família',
      dataIndex: 'family',
      key: 'family',
      render: (text: IFamily) => (
        <Tag className="m-0">
          <b>{text.name}</b>
        </Tag>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
      key: 'group',
      render: (text: IGroup) => (
        <Tag className="m-0">
          <b>{text.name}</b>
        </Tag>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Cadastro',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (text: any, record: any) => moment(text).format('DD/MM/YYYY'),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text: any, record: any) => (
        <span>
          <Tooltip title="Ver mais">
            <Typography.Link
              href={`/painel/produtos/${record.id}`}
              style={{ marginRight: 8 }}
              disabled
            >
              <AiTwotoneEye size={18} />
            </Typography.Link>
          </Tooltip>
          <Tooltip title="Editar">
            <Typography.Link
              href={`/painel/produtos/${record.id}/editar`}
              style={{ marginRight: 8 }}
            >
              <AiTwotoneEdit size={18} />
            </Typography.Link>
          </Tooltip>
          <Tooltip title="Lixeira">
            <Popconfirm
              style={{ minWidth: 120 }}
              title="Tem certeza?"
              onConfirm={() => handleRemove(record.id)}
            >
              <Typography.Link style={{ marginLeft: 8 }}>
                <BsTrashFill size={18} />
              </Typography.Link>
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ] as any;
