import { Route } from 'react-router-dom';

import SuppliersScreen from 'src/screens/suppliers/suppliers.component';
import SuppliersNewScreen from 'src/screens/suppliers/new/new.component';
import SuppliersEditScreen from 'src/screens/suppliers/edit/edit.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const suppliersRouteGroup = (
  <Route path="fornecedores">
    <Route index element={<PrivateRoute children={<SuppliersScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<SuppliersNewScreen />} />}
    />
    <Route
      path=":id/editar"
      element={<PrivateRoute children={<SuppliersEditScreen />} />}
    />
  </Route>
);

export default suppliersRouteGroup;
