import { api } from './';

import { ICreateGroupInput, IUpdateGroupInput } from 'src/interfaces/groups';

const GroupsService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/groups', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/groups/${id}`);
    return res.data;
  },
  create: async (input: ICreateGroupInput) => {
    const res = await api.post('/groups', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateGroupInput) => {
    const res = await api.put(`/groups/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/groups/${id}`);
    return res.data;
  },
};

export default GroupsService;
