import { IFamily, IPaginateFamily } from 'src/interfaces/families';

export const FETCH_FAMILIES = 'FETCH_FAMILIES';
export const FETCH_FAMILY = 'FETCH_FAMILY';

export interface IReducerFamilyState {
  all?: IPaginateFamily;
  current?: IFamily;
}

export interface IFetchFamilies {
  type: typeof FETCH_FAMILIES;
  payload: IPaginateFamily;
}

export interface IFetchFamily {
  type: typeof FETCH_FAMILY;
  payload: IFamily;
}

export type FamilyActionTypes = IFetchFamilies | IFetchFamily;
