import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Button = styled(AntdButton)<{ color?: string }>`
  background: ${(props) => !!props?.color && props.color};
  background: '1px solid ${(props) => !!props?.color && props.color}';
`;

export const Container = styled.div<{ type?: string }>`
  background-color: ${(props) => (props.type === 'dark' ? '#333' : 'white')};
  border-radius: 0px;
  border: none;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  margin-bottom: 30px;
  margin-top: 1.5rem;
  transition: all 0.5s ease-in-out;
  width: 100%;
  word-wrap: break-word;
  ${(props) =>
    props.type === 'blur' &&
    'backdrop-filter: blur(13px); -webkit-backdrop-filter: blur(13px); background-color: rgba(255,255,255, .6);'}
`;

export const CardHeader = styled.div<{ type?: string }>`
  background-color: transparent;
  border-bottom: 1px solid
    ${(props) => (props.type === 'dark' ? '#444' : '#f1f1f1')};
  margin: 0;
  padding: 15px 25px;
  position: relative;

  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
`;

export const Title = styled.h5<{ type?: string }>`
  color: ${(props) => (props.type === 'dark' ? 'white' : '#333')};
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;

  &:after {
    background-image: linear-gradient(45deg, #4074b3, #5ec99c);
    content: '';
    height: 30px;
    left: -26px;
    position: absolute;
    top: -5px;
    width: 4px;
  }
`;

export const Subtitle = styled.p<{ type?: string }>`
  color: ${(props) => (props.type === 'dark' ? '#f9f9f9' : '#666')};
  font-size: 13px;
  margin: 0;
`;

const styles = {
  required: {
    color: 'var(--app-red)',
    fontSize: '18px',
    marginLeft: '3px',
  } as React.CSSProperties,
};

export default styles;
