import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification } from 'src/utils';
import { ICreateGroupInput } from 'src/interfaces/groups';
import { IProps } from './new.type';

const useGroups = (props: IProps) => {
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const handleSubmit = async (input: ICreateGroupInput) => {
    setSending(true);

    try {
      const data = await props.createGroup(input);

      if (!!data) {
        Notification.success({
          title: 'Ok',
          message: 'Grupo cadastrado com sucesso!',
        });

        navigate(-1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setSending(false);
    }
  };

  return {
    handleSubmit,
    sending,
  };
};

export default useGroups;
