import { Route } from 'react-router-dom';

import ColorsScreen from 'src/screens/colors/colors.component';
import ColorsNewScreen from 'src/screens/colors/new/new.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const colorsRouteGroup = (
  <Route path="cores">
    <Route index element={<PrivateRoute children={<ColorsScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<ColorsNewScreen />} />}
    />
  </Route>
);

export default colorsRouteGroup;
