import { Tooltip, Typography, Popconfirm, Popover } from 'antd';
import {
  AiTwotoneEdit,
  AiFillMessage,
  AiFillPhone,
  AiFillMail,
} from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import { colors } from 'src/utils';

export const columns = (handleRemove: any) =>
  [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <>
          <p className="mb-0 d-flex justify-content-center align-items-center">
            {record.fantasyName}
          </p>
          <p
            style={{
              fontSize: 11,
              fontWeight: '600',
              textTransform: 'uppercase',
            }}
            className="mb-0"
          >
            {text}
          </p>
        </>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'CNPJ',
      dataIndex: 'documentNumber',
      key: 'documentNumber',
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Contato',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (text: any, record: any) => (
        <>
          <p className="mb-0 d-flex justify-content-center align-items-center">
            <AiFillPhone
              color={colors.theme.secondary}
              size={16}
              className="me-1"
            />{' '}
            {text}
          </p>
          <p className="mb-0 d-flex justify-content-center align-items-center">
            <AiFillMail
              color={colors.theme.secondary}
              size={16}
              className="me-1"
            />{' '}
            {record.email}
          </p>
        </>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: any) => (
        <span>
          {record.address} {record?.addressNumber || 'S/N'},{' '}
          {record.neighborhood}. {record.city}-{record.state},{' '}
          {record.postalCode}
        </span>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Obs.',
      dataIndex: 'observations',
      key: 'observations',
      render: (text: any) => (
        <span>
          <Popover
            style={{ minWidth: 120, maxWidth: 300 }}
            content={text || '-'}
          >
            <Typography.Link>
              <AiFillMessage size={18} />
            </Typography.Link>
          </Popover>
        </span>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text: any, record: any) => (
        <span>
          <Tooltip title="Editar">
            <Typography.Link
              href={`/painel/fornecedores/${record.id}/editar`}
              style={{ marginRight: 8 }}
            >
              <AiTwotoneEdit size={18} />
            </Typography.Link>
          </Tooltip>
          <Tooltip title="Lixeira">
            <Popconfirm
              style={{ minWidth: 120 }}
              title="Tem certeza?"
              onConfirm={() => handleRemove(record.id)}
            >
              <Typography.Link style={{ marginLeft: 8 }}>
                <BsTrashFill size={18} />
              </Typography.Link>
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ] as any;
