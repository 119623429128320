import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import InputMask from 'react-input-mask';

import { Card } from 'src/components';

import { rules } from '../../edit.utils';

const { TextArea } = Input;

const InfoComponent: React.FC<{}> = () => {
  return (
    <Card title="Informações">
      <Row gutter={16}>
        <Col span={24} lg={12}>
          <Form.Item label="Razão Social" name="name" rules={rules.name}>
            <Input placeholder="Razão Social" />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            label="Nome Fantasia"
            name="fantasyName"
            rules={rules.fantasyName}
          >
            <Input placeholder="Nome Fantasia" />
          </Form.Item>
        </Col>
        <Col span={24} lg={8}>
          <Form.Item
            label="CNPJ"
            name="documentNumber"
            rules={rules.documentNumber}
          >
            <InputMask mask="99.999.999/9999-99">
              {(inputProps: any) => (
                <Input placeholder="CNPJ" {...inputProps} />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} lg={12}>
          <Form.Item
            label="Observações"
            name="observations"
            rules={rules.observations}
          >
            <TextArea rows={4} placeholder="Observações" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoComponent;
