export const rules = {
  name: [{ required: true }],
  fantasyName: [{ required: true }],
  documentNumber: [{ required: true }],
  email: [{ required: true }],
  phoneNumber: [{ required: true }],
  observations: [{ required: true }],
  address: [{ required: true }],
  addressNumber: [{ required: false }],
  neighborhood: [{ required: true }],
  postalCode: [{ required: true }],
  city: [{ required: true }],
  state: [{ required: true }],
};
