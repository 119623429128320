import { Tooltip, Typography, Popconfirm } from 'antd';
import { BsTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';

import { ColumnsActions, DataType } from './families.type';
import { ColumnsType } from 'antd/lib/table';

const defaultColumns = (props: ColumnsActions) => [
  {
    title: 'Descrição',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    editable: true,
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: 'Ações',
    width: 150,
    render: (_: any, record: DataType) => {
      const editable = props.isEditing(record);

      return editable ? (
        <span>
          <Typography.Link
            onClick={() => props.handleSave(record.id)}
            className="me-2"
          >
            Salvar
          </Typography.Link>
          <Popconfirm
            title="Tem certeza?"
            onConfirm={props.handleCancel}
            style={{ minWidth: 120 }}
          >
            <Typography.Link>Cancelar</Typography.Link>
          </Popconfirm>
        </span>
      ) : (
        <span>
          <Tooltip title="Editar">
            <Typography.Link
              disabled={props.editingKey !== ''}
              onClick={() => props.handleEdit(record)}
              className="me-2"
            >
              <AiFillEdit size={18} />
            </Typography.Link>
          </Tooltip>
          <Tooltip title="Lixeira">
            <Popconfirm
              style={{ minWidth: 120 }}
              title="Tem certeza?"
              onConfirm={() => props.handleRemove(record.id)}
            >
              <Typography.Link>
                <BsTrashFill size={18} />
              </Typography.Link>
            </Popconfirm>
          </Tooltip>
        </span>
      );
    },
  },
];

export const columns: ColumnsType<DataType> | any = (props: ColumnsActions) =>
  defaultColumns(props).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: props.isEditing(record),
      }),
    };
  });
