import { Dispatch } from 'react';

import SupplierService from 'src/services/suppliers.service';

import {
  ISupplier,
  ICreateSupplierInput,
  IUpdateSupplierInput,
  IPaginateSupplier,
} from 'src/interfaces/suppliers';

import { request } from 'src/utils';

import { FETCH_SUPPLIERS, FETCH_SUPPLIER, SupplierActionTypes } from './types';

export const fetchSuppliers =
  (pageNumber: number) => async (dispatch: Dispatch<SupplierActionTypes>) => {
    try {
      const response: IPaginateSupplier = await SupplierService.all(pageNumber);

      dispatch({
        type: FETCH_SUPPLIERS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchSupplier =
  (id: string) => async (dispatch: Dispatch<SupplierActionTypes>) => {
    try {
      const response: ISupplier = await SupplierService.current(id);

      dispatch({
        type: FETCH_SUPPLIER,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createSupplier = (input: ICreateSupplierInput) => async () => {
  input.addressNumber = Number(input.addressNumber) || undefined;

  try {
    const response: ISupplier = await SupplierService.create(input);
    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateSupplier =
  (id: string, input: IUpdateSupplierInput) => async () => {
    try {
      const response: ISupplier = await SupplierService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeSupplier = (id: string) => async () => {
  try {
    const response: any = await SupplierService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
