import { Dispatch } from 'react';

import ProductService from 'src/services/products.service';
import GroupsService from 'src/services/groups.service';

import {
  IProduct,
  ICreateProductInput,
  IUpdateProductInput,
  IPaginateProduct,
  IInformations,
} from 'src/interfaces/products';
import { IGroup } from 'src/interfaces/groups';
import { request } from 'src/utils';

import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  FETCH_INFORMATIONS,
  FETCH_SUB_GROUPS,
  ProductActionTypes,
} from './types';

export const fetchProducts =
  (pageNumber: number) => async (dispatch: Dispatch<ProductActionTypes>) => {
    try {
      const response: IPaginateProduct = await ProductService.all(pageNumber);

      dispatch({
        type: FETCH_PRODUCTS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchProduct =
  (id: string) => async (dispatch: Dispatch<ProductActionTypes>) => {
    try {
      const response: IProduct = await ProductService.current(id);

      dispatch({
        type: FETCH_PRODUCT,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchInformations =
  () => async (dispatch: Dispatch<ProductActionTypes>) => {
    try {
      const response: IInformations = await ProductService.info();

      dispatch({
        type: FETCH_INFORMATIONS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchGroup =
  (id: string) => async (dispatch: Dispatch<ProductActionTypes>) => {
    try {
      const response: IGroup = await GroupsService.current(id);

      dispatch({
        type: FETCH_SUB_GROUPS,
        payload: response.subGroups,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createProduct = (input: ICreateProductInput) => async () => {

  try {
    const response: IProduct = await ProductService.create(input);
    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateProduct =
  (id: string, input: IUpdateProductInput) => async () => {
    try {
      const response: IProduct = await ProductService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeProduct = (id: string) => async () => {
  try {
    const response: any = await ProductService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
