import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import { IReducerConfigState } from './config/types';
import { IReducerAuthState } from './auth/types';
import { IReducerColorState } from './colors/types';
import { IReducerFamilyState } from './families/types';
import { IReducerGroupState } from './groups/types';
import { IReducerProductState } from './products/types';
import { IReducerSubGroupState } from './sub-groups/types';
import { IReducerSupplierState } from './suppliers/types';

const middleware = [thunk];

export const store = createStore(
  rootReducer,
  undefined,
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middleware)
    : require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(...middleware)
      )
);

export const persistor = persistStore(store);

export interface IReduxState {
  config: IReducerConfigState;
  auth: IReducerAuthState;
  colors: IReducerColorState;
  families: IReducerFamilyState;
  groups: IReducerGroupState;
  products: IReducerProductState;
  subGroups: IReducerSubGroupState;
  suppliers: IReducerSupplierState;
}
