import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineBgColors,
  AiOutlineGroup,
  AiOutlineSubnode,
} from 'react-icons/ai';

import { GiFamilyTree } from 'react-icons/gi';
import { BiUserPin } from 'react-icons/bi';
import { BsCartPlus, BsCartFill } from 'react-icons/bs';

import { logout } from 'src/store/auth/actions';
import { images } from 'src/utils';

import Divider from './components/divider/divider.component';
import { IProps } from './sider.type';
import { LogoWide, LogoIcon } from './sider.style';

const LayoutSider: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { collapsed } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  const collapsedTitle = (text: string, collapsed: boolean) => {
    if (!collapsed) {
      return text;
    }
    return;
  };

  return (
    <>
      <Link to="/">
        <LogoIcon
          src={images.icons.default}
          alt="logo"
          title="logo"
          width="45px"
          collapsed={collapsed}
        />
        <LogoWide
          src={images.logo.white}
          alt="logo"
          title="logo"
          width="80%"
          collapsed={collapsed}
        />
      </Link>

      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Divider content={collapsedTitle('Dashboard', collapsed)} noBorder />
        <Menu.Item
          key="1"
          icon={<AiOutlineHome />}
          onClick={() => navigate('/painel/')}
        >
          Início
        </Menu.Item>

        <Divider content={collapsedTitle('Produtos', collapsed)} noBorder />

        <Menu.Item
          key="4"
          icon={<BsCartFill />}
          onClick={() => navigate('/painel/produtos')}
        >
          Todos
        </Menu.Item>

        <Menu.Item
          key="5"
          icon={<BsCartPlus />}
          onClick={() => navigate('/painel/produtos/novo')}
        >
          Cadastrar
        </Menu.Item>

        <Menu.Item
          key="6"
          icon={<BiUserPin />}
          onClick={() => navigate('/painel/fornecedores')}
        >
          Fornecedores
        </Menu.Item>

        <Divider
          content={collapsedTitle('Configurações', collapsed)}
          noBorder
        />
        <Menu.Item
          key="7"
          icon={<AiOutlineBgColors />}
          onClick={() => navigate('/painel/cores')}
        >
          Cores
        </Menu.Item>
        <Menu.Item
          key="8"
          icon={<GiFamilyTree />}
          onClick={() => navigate('/painel/familias')}
        >
          Familia
        </Menu.Item>
        <Menu.Item
          key="9"
          icon={<AiOutlineGroup />}
          onClick={() => navigate('/painel/grupos')}
        >
          Grupos
        </Menu.Item>
        <Menu.Item
          key="10"
          icon={<AiOutlineSubnode />}
          onClick={() => navigate('/painel/subgrupos')}
        >
          Subgrupos
        </Menu.Item>

        <Menu.Item key="11" icon={<AiOutlineLogout />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </>
  );
};

export default connect(undefined, { logout })(LayoutSider);
