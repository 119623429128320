import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Skeleton } from 'antd';

import { Container, Card, Title, GoBack, FormFinish } from 'src/components';
import {
  updateProduct,
  fetchInformations,
  fetchGroup,
  fetchProduct,
} from 'src/store/products/actions';

import InfoComponent from './components/info/info.component';
// import VariantComponent from './components/variant/variant.component';

import { IProps } from './edit.type';
import useProducts from './edit.use';
import { initialValues } from './edit.utils';

const EditScreen: React.FC<IProps> = (props) => {
  useProducts(props);

  const { form, item, sending, handleSubmit, groups, families, handleGroup } =
    useProducts(props);

  console.log(item);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Produtos" title="Edite o produto" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {!!item ? (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={initialValues(item)}
            >
              <InfoComponent
                groups={groups}
                families={families}
                handleGroup={handleGroup}
              />
              {/* <VariantComponent colors={colors} subGroups={subGroups} /> */}
              <FormFinish sending={sending} />
            </Form>
          ) : (
            <Card>
              <Skeleton active />
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, {
  updateProduct,
  fetchInformations,
  fetchGroup,
  fetchProduct,
})(EditScreen);
