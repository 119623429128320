import { Dispatch } from 'react';

import ColorsService from 'src/services/colors.service';

import {
  IColor,
  ICreateColorInput,
  IUpdateColorInput,
  IPaginateColors,
} from 'src/interfaces/colors';

import { request } from 'src/utils';

import { FETCH_COLORS, FETCH_COLOR, ColorActionTypes } from './types';

export const fetchColors =
  (pageNumber: number) => async (dispatch: Dispatch<ColorActionTypes>) => {
    try {
      const response: IPaginateColors = await ColorsService.all(pageNumber);

      dispatch({
        type: FETCH_COLORS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchColor =
  (id: string) => async (dispatch: Dispatch<ColorActionTypes>) => {
    try {
      const response: IColor = await ColorsService.current(id);

      dispatch({
        type: FETCH_COLOR,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createColor = (input: ICreateColorInput) => async () => {
  try {
    const response: IColor = await ColorsService.create(input);

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateColor =
  (id: string, input: IUpdateColorInput) => async () => {
    try {
      const response: IColor = await ColorsService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeColor = (id: string) => async () => {
  try {
    const response: any = await ColorsService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
