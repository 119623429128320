import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Card } from 'src/components';
import RedirectIcon from 'src/assets/icons/key.gif';
import { Notification } from 'src/utils';
import { verifyIdentity } from 'src/store/auth/actions';

import { IProps } from './redirect.type';
import { Wrapper, Title, Image, Subtitle } from './redirect.style';

const RedirectScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const handleSignIn = async () => {
    const authentication_token = cookies._jwtToken;

    if (!!authentication_token) {
      try {
        const response = await props.verifyIdentity();

        if (!!response) {
          navigate('painel/');
        }
      } catch {
        Notification.error({
          message: 'Ocorreu um erro tente novamente mais tarde.',
        });
      }
    } else {
      Notification.warning({
        message: 'Você precisa estar logado para acessar essa página.',
      });

      navigate('/');
    }
  };

  useEffect(() => {
    handleSignIn();
  });

  return (
    <>
      <Wrapper>
        <div className="py-5" style={{ width: '100%' }}>
          <Row justify="center" align="middle">
            <Col xs={20} sm={20} md={14} lg={8} xxl={6}>
              <Card>
                <Image src={RedirectIcon} alt="login" />
                <Title>Confirmando Identidade</Title>
                <Subtitle>
                  Você será redirecionado para o módulo de estoque em breve
                </Subtitle>
              </Card>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </>
  );
};

export default connect(undefined, { verifyIdentity })(RedirectScreen);
