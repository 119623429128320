import { IPaginateSupplier, ISupplier } from 'src/interfaces/suppliers';

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';

export interface IReducerSupplierState {
  all?: IPaginateSupplier;
  current?: ISupplier;
}

export interface IFetchSuppliers {
  type: typeof FETCH_SUPPLIERS;
  payload: IPaginateSupplier;
}

export interface IFetchSupplier {
  type: typeof FETCH_SUPPLIER;
  payload: ISupplier;
}

export type SupplierActionTypes = IFetchSuppliers | IFetchSupplier;
