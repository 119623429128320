import React from 'react';
import { Row, Col, Table, Button } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Container, Title, GoBack } from 'src/components';
import { table } from 'src/utils';
import { IReduxState } from 'src/store';
import { fetchSuppliers, removeSupplier } from 'src/store/suppliers/actions';

import { IProps } from './suppliers.type';
import { columns } from './suppliers.utils';
import useSuppliers from './suppliers.use';

import Pagination from './components/pagination/pagination.component';

const SuppliersScreen: React.FC<IProps> = (props) => {
  useSuppliers(props);

  const { suppliers } = props;
  const { handleRemove, handleSuppliers } = useSuppliers(props);

  const navigate = useNavigate();

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Configurações"
            title="Fornecedores"
            subtitle="Visualize abaixo a lista de fornecedores cadastrados no sistema, clique no botão ao lado para adicionar mais."
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => navigate('/painel/fornecedores/novo')}
          >
            Adicionar Novo
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Table
              {...table}
              rowKey={(record: any) => record.id}
              columns={columns(handleRemove)}
              dataSource={suppliers?.data}
              bordered
              pagination={false}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Pagination
            pagesMeta={suppliers?.meta}
            handleSearch={handleSuppliers}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  suppliers: state.suppliers.all,
});

export default connect(mapStateToProps, { fetchSuppliers, removeSupplier })(
  SuppliersScreen
);
