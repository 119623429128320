import { Route } from 'react-router-dom';

import ProductsScreen from 'src/screens/products/products.component';
import ProductsNewScreen from 'src/screens/products/new/new.component';
import ProductsEditScreen from 'src/screens/products/edit/edit.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const productsRouteGroup = (
  <Route path="produtos">
    <Route index element={<PrivateRoute children={<ProductsScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<ProductsNewScreen />} />}
    />
    <Route
      path=":id/editar"
      element={<PrivateRoute children={<ProductsEditScreen />} />}
    />
  </Route>
);

export default productsRouteGroup;
