import { Route } from 'react-router-dom';

import SubGroupsScreen from 'src/screens/sub-groups/sub-groups.component';
import SubGroupsNewScreen from 'src/screens/sub-groups/new/new.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const subGroupsRouteGroup = (
  <Route path="subgrupos">
    <Route index element={<PrivateRoute children={<SubGroupsScreen />} />} />
    <Route
      path=":id/novo"
      element={<PrivateRoute children={<SubGroupsNewScreen />} />}
    />
  </Route>
);

export default subGroupsRouteGroup;
