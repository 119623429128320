import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification } from 'src/utils';
import { ICreateFamilyInput } from 'src/interfaces/families';
import { IProps } from './new.type';

const useFamilies = (props: IProps) => {
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const handleSubmit = async (input: ICreateFamilyInput) => {
    setSending(true);

    try {
      const data = await props.createFamily(input);

      if (!!data) {
        Notification.success({
          title: 'Ok',
          message: 'Família cadastrada com sucesso!',
        });

        navigate(-1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setSending(false);
    }
  };

  return {
    handleSubmit,
    sending,
  };
};

export default useFamilies;
