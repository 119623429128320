import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification } from 'src/utils';
import { ICreateColorInput } from 'src/interfaces/colors';
import { IProps } from './new.type';

const useColors = (props: IProps) => {
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const handleSubmit = async (input: ICreateColorInput) => {
    setSending(true);

    try {
      const data = await props.createColor(input);

      if (!!data) {
        Notification.success({
          title: 'Ok',
          message: 'Cor cadastrada com sucesso!',
        });

        navigate(-1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setSending(false);
    }
  };

  return {
    handleSubmit,
    sending,
  };
};

export default useColors;
