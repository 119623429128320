import { Form } from 'antd';
import { useState, useEffect } from 'react';

import { Notification } from 'src/utils';
import { IProps, DataType } from './sub-groups.type';

const useSubGroups = (props: IProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<[]>([]);

  const [editingKey, setEditingKey] = useState<any>('');

  useEffect(() => {
    handleSubGroups(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubGroups = async (pageNumber: number) => {
    setLoading(true);
    handleCancel();

    try {
      const { data } = await props.fetchSubGroups(pageNumber);

      setData(data);
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const isEditing = (record: DataType) => record.id === editingKey;

  const handleEdit = (record: Partial<DataType> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', groupId: record.groupId, ...record });
    setEditingKey(record.id);
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const handleSave = async (id: string) => {
    try {
      const row = (await form.validateFields()) as DataType;

      const newData: any = [...data];

      const index = newData.findIndex((item: any) => id === item.id);

      const response = await props.updateSubGroup(id, {
        name: row.name,
        groupId: row.groupId,
      });

      if (!!response) {
        Notification.success({
          title: 'Ok',
          message: 'Subgrupo atualizado com sucesso',
        });
      }

      if (index > -1) {
        const item: any = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      return Notification.error({
        title: 'Erro',
        message: 'Ocorreu um erro ao atualizar o subgrupo',
      });
    }
  };

  const handleRemove = async (id: string) => {
    setLoading(true);

    try {
      const data = await props.removeSubGroup(id);

      if (data) {
        handleSubGroups(1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    data,
    loading,
    isEditing,
    editingKey,
    handleSubGroups,
    handleEdit,
    handleSave,
    handleCancel,
    handleRemove,
  };
};

export default useSubGroups;
