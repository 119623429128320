import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { IFamily } from 'src/interfaces/families';
import { IGroup } from 'src/interfaces/groups';
import { ISubGroup } from 'src/interfaces/sub-groups';
import { IColor } from 'src/interfaces/colors';

import { Notification } from 'src/utils';
import { ICreateProductInput } from 'src/interfaces/products';
import { IProps } from './new.type';

import { store } from 'src/store';

const useProducts = (props: IProps) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<[DefaultOptionType] | []>([]);
  const [subGroups, setSubGroups] = useState<[DefaultOptionType] | []>([]);
  const [families, setFamilies] = useState<[DefaultOptionType] | []>([]);
  const [colors, setColors] = useState<[DefaultOptionType] | []>([]);

  useEffect(() => {
    handleInformations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInformations = async () => {
    setLoading(true);

    try {
      const res = await props.fetchInformations();

      const groups: [DefaultOptionType] | any = res.groups?.map(
        (item: IGroup) => ({
          value: item.id,
          label: item.name,
        })
      );

      const families: [DefaultOptionType] | any = res.families?.map(
        (item: IFamily) => ({
          value: item.id,
          label: item.name,
        })
      );

      const colors: [DefaultOptionType] | any = res.colors?.map(
        (item: IColor) => ({
          value: item.id,
          label: item.name,
        })
      );

      setGroups(groups);
      setFamilies(families);
      setColors(colors);
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleGroup = async (id: string) => {
    try {
      const res = await props.fetchGroup(id);

      const options: [DefaultOptionType] | any = res.subGroups?.map(
        (item: ISubGroup) => ({
          value: item.id,
          label: item.name,
        })
      );

      setSubGroups(options);
    } catch (err: any) {
      return false;
    }
  };

  const handleSubmit = async (input: ICreateProductInput) => {
    setSending(true);

    const data: any = store.getState().products.new || [];

    const formData = new FormData();

    formData.append('name', input.name);
    formData.append('groupId', String(input.groupId));
    formData.append('familyId', String(input.familyId));
    formData.append('ncm', input.ncm);
    formData.append('unit', input.unit);
    formData.append('image', input.image);

    for (let i = 0; i < data?.length; i++) {
      formData.append(
        `productVariations[${i}][subGroupId]`,
        data?.[i].subGroup.value
      );

      formData.append(
        `productVariations[${i}][colorId]`,
        data?.[i].color.value
      );
    }

    try {
      const data = await props.createProduct(formData);

      if (!!data) {
        Notification.success({
          title: 'Ok',
          message: 'Produto cadastrado com sucesso!',
        });

        navigate(-1);
      }
    } catch (err: any) {
      console.dir(err, 'erro');
      return false;
    } finally {
      setSending(false);
    }
  };

  return {
    form,
    loading,
    families,
    groups,
    colors,
    subGroups,
    handleGroup,
    handleSubmit,
    sending,
  };
};

export default useProducts;
