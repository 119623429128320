import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { IReduxState } from 'src/store';
import { scrollToTop } from 'src/utils';

export const PrivateRoute: React.FC = ({ children }: any) => {
  scrollToTop();

  const [cookies] = useCookies();

  const token = !!useSelector((state: IReduxState) => state.auth?.token);
  const cookieToken = !!cookies?._jwtToken;

  const hasAccess = token && cookieToken;
  // const hasPartialAccess = !token && cookieToken;

  return hasAccess ? children : <Navigate to="/" replace />;
};
