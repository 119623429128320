import { Dispatch } from 'react';

import GroupsService from 'src/services/groups.service';

import {
  IGroup,
  ICreateGroupInput,
  IUpdateGroupInput,
  IPaginateGroups,
} from 'src/interfaces/groups';

import { request } from 'src/utils';

import { FETCH_GROUPS, FETCH_GROUP, GroupActionTypes } from './types';

export const fetchGroups =
  (pageNumber: number) => async (dispatch: Dispatch<GroupActionTypes>) => {
    try {
      const response: IPaginateGroups = await GroupsService.all(pageNumber);

      dispatch({
        type: FETCH_GROUPS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchGroup =
  (id: string) => async (dispatch: Dispatch<GroupActionTypes>) => {
    try {
      const response: IGroup = await GroupsService.current(id);

      dispatch({
        type: FETCH_GROUP,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createGroup = (input: ICreateGroupInput) => async () => {
  try {
    const response: IGroup = await GroupsService.create(input);

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateGroup =
  (id: string, input: IUpdateGroupInput) => async () => {
    try {
      const response: IGroup = await GroupsService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeGroup = (id: string) => async () => {
  try {
    const response: any = await GroupsService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
