import { Dispatch } from 'react';

import FamiliesService from 'src/services/families.service';

import {
  IFamily,
  ICreateFamilyInput,
  IUpdateFamilyInput,
  IPaginateFamily,
} from 'src/interfaces/families';

import { request } from 'src/utils';

import { FETCH_FAMILIES, FETCH_FAMILY, FamilyActionTypes } from './types';

export const fetchFamilies =
  (pageNumber: number) => async (dispatch: Dispatch<FamilyActionTypes>) => {
    try {
      const response: IPaginateFamily = await FamiliesService.all(pageNumber);

      dispatch({
        type: FETCH_FAMILIES,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchFamily =
  (id: string) => async (dispatch: Dispatch<FamilyActionTypes>) => {
    try {
      const response: IFamily = await FamiliesService.current(id);

      dispatch({
        type: FETCH_FAMILY,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createFamily = (input: ICreateFamilyInput) => async () => {
  try {
    const response: IFamily = await FamiliesService.create(input);

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateFamily =
  (id: string, input: IUpdateFamilyInput) => async () => {
    try {
      const response: IFamily = await FamiliesService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeFamily = (id: string) => async () => {
  try {
    const response: any = await FamiliesService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
