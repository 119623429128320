import { useState, useEffect } from 'react';
import { IProps } from './suppliers.type';

const useSuppliers = (props: IProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleSuppliers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuppliers = async (pageNumber: number) => {
    setLoading(true);

    try {
      await props.fetchSuppliers(pageNumber);
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (id: string) => {
    setLoading(true);

    try {
      const data = await props.removeSupplier(id);

      if (data) {
        handleSuppliers(1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    handleRemove,
    handleSuppliers,
    loading,
  };
};

export default useSuppliers;
