import React, { useLayoutEffect, useState } from 'react';

import guestRoutes from './guest/guest.component';
import userRoutes from './user/user.component';

import { IProps } from './routes.type';
import { BrowserRouterProps, Router, Routes } from 'react-router-dom';

export const BrowserRouter = ({
  basename,
  children,
  history,
}: BrowserRouterProps | any) => {
  const [state, setState] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

class RouteList extends React.PureComponent<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <Routes>
        {guestRoutes}
        {userRoutes}
      </Routes>
    );
  }
}

export default RouteList;
