export const rules = {
  name: [{ required: true }],
  unit: [{ required: true }],
  ncm: [{ required: true }],
  groupId: [{ required: true }],
  familyId: [{ required: true }],
  image: [{ required: true }],
  uuid: [{ required: false }],
  colorId: [{ required: false }],
  subGroupId: [{ required: false }],
};
