import React from 'react';
import { Row, Col, Button, Table, Skeleton, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, Container, Title, GoBack } from 'src/components';
import { table } from 'src/utils';
import { IReduxState } from 'src/store';
import {
  fetchGroups,
  removeGroup,
  updateGroup,
} from 'src/store/groups/actions';

import EditableCell from './components/cell/cell.component';
import Pagination from './components/pagination/pagination.component';

import { IProps } from './groups.type';
import { columns } from './groups.utils';
import useGroups from './groups.use';

const GroupsScreen: React.FC<IProps> = (props) => {
  useGroups(props);

  const { groups } = props;

  const {
    loading,
    data,
    form,
    handleGroups,
    editingKey,
    isEditing,
    handleEdit,
    handleSave,
    handleCancel,
    handleRemove,
  } = useGroups(props);

  const navigate = useNavigate();

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Configurações"
            title="Grupos"
            subtitle="Visualize abaixo a lista de grupos cadastrados no sistema, clique no botão ao lado para adicionar mais."
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => navigate('/painel/grupos/novo')}
          >
            Adicionar Novo
          </Button>
        </Col>
      </Row>
      {!loading ? (
        <Row gutter={16}>
          <Col span={24}>
            <Card>
              <Form form={form} component={false}>
                <Table
                  {...table}
                  bordered
                  components={components}
                  dataSource={data}
                  rowKey={(record: any) => record.id}
                  columns={columns({
                    editingKey,
                    isEditing,
                    handleEdit,
                    handleSave,
                    handleCancel,
                    handleRemove,
                  })}
                  rowClassName="editable-row"
                  pagination={false}
                />
              </Form>
            </Card>
          </Col>
          <Col span={24}>
            <Pagination pagesMeta={groups?.meta} handleSearch={handleGroups} />
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <Card>
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  groups: state.groups.all,
});

export default connect(mapStateToProps, {
  fetchGroups,
  removeGroup,
  updateGroup,
})(GroupsScreen);
