import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerSupplierState,
  FETCH_SUPPLIER,
  FETCH_SUPPLIERS,
  SupplierActionTypes,
} from './types';

const initialState: IReducerSupplierState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'suppliers',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: SupplierActionTypes) => {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_SUPPLIER:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
