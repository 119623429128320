import {
  IPaginateProduct,
  IProduct,
  IInformations,
} from 'src/interfaces/products';
import { ISubGroup } from 'src/interfaces/sub-groups';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_INFORMATIONS = 'FETCH_INFORMATIONS';
export const FETCH_SUB_GROUPS = 'FETCH_SUB_GROUPS';
export const UPDATE_VARIANT_TO_TABLE = 'UPDATE_VARIANT_TO_TABLE';

interface IVariant {
  uuid: string;
  colorId: string;
  subGroupId: string;
}

interface INew {
  productVariants?: IVariant[];
}

export interface IReducerProductState {
  all?: IPaginateProduct;
  current?: IProduct;
  new?: INew;
  informations?: IInformations;
  subGroups?: ISubGroup[];
}

export interface IFetchProducts {
  type: typeof FETCH_PRODUCTS;
  payload: IPaginateProduct;
}

export interface IFetchProduct {
  type: typeof FETCH_PRODUCT;
  payload: IProduct;
}

export interface IFetchInformations {
  type: typeof FETCH_INFORMATIONS;
  payload: IInformations;
}

export interface IFetchSubGroups {
  type: typeof FETCH_SUB_GROUPS;
  payload: ISubGroup[];
}

export interface IUpdateVariantToTable {
  type: typeof UPDATE_VARIANT_TO_TABLE;
  payload: any;
}

export type ProductActionTypes =
  | IFetchProducts
  | IFetchProduct
  | IFetchInformations
  | IFetchSubGroups
  | IUpdateVariantToTable;
