import { combineReducers } from 'redux';

import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import colorsReducer from './colors/reducers';
import familiesReducer from './families/reducers';
import suppliersReducer from './suppliers/reducers';
import groupsReducer from './groups/reducers';
import subGroupsReducer from './sub-groups/reducers';
import productsReducer from './products/reducers';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  colors: colorsReducer,
  families: familiesReducer,
  groups: groupsReducer,
  products: productsReducer,
  subGroups: subGroupsReducer,
  suppliers: suppliersReducer,
});
