import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Skeleton } from 'antd';

import { Container, Title, FormFinish, GoBack, Card } from 'src/components';

import { updateSupplier, fetchSupplier } from 'src/store/suppliers/actions';

import ContactComponent from './components/contact/contact.component';
import InfoComponent from './components/info/info.component';
import AddressComponent from './components/address/address.component';

import { IProps } from './edit.type';
import { initialValues } from './edit.utils';
import useSuppliers from './edit.use';

const EditScreen: React.FC<IProps> = (props) => {
  useSuppliers(props);

  const { item, sending, handleSubmit } = useSuppliers(props);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Fornecedores" title="Editar fornecedor" />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          {!!item ? (
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={initialValues(item)}
            >
              <InfoComponent />
              <ContactComponent />
              <AddressComponent />
              <FormFinish sending={sending} />
            </Form>
          ) : (
            <Card>
              <Skeleton active />
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { updateSupplier, fetchSupplier })(
  EditScreen
);
