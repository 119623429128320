import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Notification } from 'src/utils';
import { ICreateSubGroupInput } from 'src/interfaces/sub-groups';
import { IProps } from './new.type';
import { IGroup } from 'src/interfaces/groups';

const useSubGroups = (props: IProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<IGroup | undefined>(undefined);

  useEffect(() => {
    handleGroup(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGroup = async (id?: string) => {
    if (!!id) {
      setLoading(true);
      try {
        const data = await props.fetchGroup(id);
        setGroup(data);
      } catch (err: any) {
        Notification.error({
          title: 'Oops!',
          message:
            'Desculpe, você precisa adicionar um grupo antes de criar um subgrupo',
        });
        navigate('/painel/grupos');
      } finally {
        setLoading(false);
      }
    } else {
      Notification.error({
        title: 'Oops!',
        message: 'Desculpe, não encontramos o grupo',
      });

      navigate('/painel/grupos');
    }
  };

  const handleSubmit = async (input: ICreateSubGroupInput) => {
    if (!!id) {
      input.groupId = id;
    }

    setSending(true);

    try {
      const data = await props.createSubGroup(input);

      if (!!data) {
        Notification.success({
          title: 'Ok',
          message: 'Subgrupo cadastrado com sucesso!',
        });

        navigate('/painel/grupos');
      }
    } catch (err: any) {
      return false;
    } finally {
      setSending(false);
    }
  };

  return {
    loading,
    group,
    handleSubmit,
    sending,
  };
};

export default useSubGroups;
