import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerProductState,
  FETCH_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_INFORMATIONS,
  FETCH_SUB_GROUPS,
  UPDATE_VARIANT_TO_TABLE,
  ProductActionTypes,
} from './types';

const initialState: IReducerProductState = {
  all: undefined,
  current: undefined,
  new: undefined,
  informations: undefined,
  subGroups: undefined,
};

const persistConfig = {
  storage,
  key: 'products',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: ProductActionTypes) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_PRODUCT:
      return {
        ...state,
        current: action.payload,
      };
    case FETCH_INFORMATIONS:
      return {
        ...state,
        informations: action.payload,
      };
    case FETCH_SUB_GROUPS:
      return {
        ...state,
        subGroups: action.payload,
      };
    case UPDATE_VARIANT_TO_TABLE:
      return {
        ...state,
        new: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
