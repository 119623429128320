import { useState, useEffect } from 'react';
import { IProps } from './products.type';

const useProducts = (props: IProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProducts = async (pageNumber: number) => {
    setLoading(true);

    try {
      await props.fetchProducts(pageNumber);
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (id: string) => {
    setLoading(true);

    try {
      const data = await props.removeProduct(id);

      if (data) {
        handleProducts(1);
      }
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    handleRemove,
    handleProducts,
    loading,
  };
};

export default useProducts;
