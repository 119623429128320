export const unit: { label: string; value: string }[] = [
  { label: 'MT - METRO', value: 'meter' },
  { label: 'UN - UNIDADE', value: 'uni' },
  { label: 'KG - KILOGRAMA', value: 'kilo' },
  { label: 'CN - CONE', value: 'cone' },
  { label: 'CX - CAIXA', value: 'box' },
  { label: 'L - LITRO', value: 'liter' },
];

export const unitTranslation = {
  meter: 'MT - METRO',
  uni: 'UN - UNIDADE',
  kilo: 'KG - KILOGRAMA',
  cone: 'CN - CONE',
  box: 'CX - CAIXA',
  liter: 'L - LITRO',
};

export default unit;
