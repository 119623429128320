import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerColorState,
  FETCH_COLOR,
  FETCH_COLORS,
  ColorActionTypes,
} from './types';

const initialState: IReducerColorState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'colors',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: ColorActionTypes) => {
  switch (action.type) {
    case FETCH_COLORS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_COLOR:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
