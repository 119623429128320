import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ISupplier } from 'src/interfaces/suppliers';
import { Notification } from 'src/utils';

import { IParams, IProps } from './edit.type';

const useSuppliers = (props: IProps) => {
  const navigate = useNavigate();

  const { id } = useParams<IParams | any>();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [item, setItem] = useState<ISupplier | undefined>(undefined);

  useEffect(() => {
    handleSupplier(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSupplier = async (id?: string) => {
    setLoading(true);

    if (!!id) {
      try {
        const data: ISupplier = await props.fetchSupplier(id);
        setItem(data);
      } catch (err: any) {
        Notification.error({
          title: 'Oops!',
          message: 'Desculpe, não encontramos o fornecedor desejado',
        });

        navigate('/painel/fornecedores');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);

      Notification.error({
        title: 'Oops!',
        message: 'Desculpe, não encontramos o fornecedor desejado',
      });
    }
  };

  const handleSubmit = async (values: any) => {
    setSending(true);

    if (!!id) {
      try {
        const data = await props.updateSupplier(id, values);

        if (!!data) {
          Notification.success({
            title: 'Ok',
            message: 'Fornecedor atualizado com sucesso',
          });

          navigate('/painel/fornecedores');
        }
      } finally {
        setSending(false);
      }
    } else {
      setSending(false);

      Notification.error({
        title: 'Oops!',
        message:
          'Ocorreu um erro ao atualizar esse fornecedor, tente novamente mais tarde.',
      });
    }
  };

  return {
    handleSubmit,
    loading,
    sending,
    item,
  };
};

export default useSuppliers;
