import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Skeleton } from 'antd';

import { Container, Card, Title, GoBack, FormFinish } from 'src/components';
import { createSubGroup } from 'src/store/sub-groups/actions';
import { fetchGroup } from 'src/store/groups/actions';

import { IProps } from './new.type';
import { rules } from './new.utils';
import useSubGroups from './new.use';

const NewScreen: React.FC<IProps> = (props) => {
  const { sending, handleSubmit, group, loading } = useSubGroups(props);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Configurações" title="Cadastre um novo subgrupo" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {!loading && !!group ? (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Card title="Informações">
                <Row gutter={16}>
                  <Col span={24} lg={12}>
                    <Form.Item label="Descrição" name="name" rules={rules.name}>
                      <Input placeholder="Descrição" />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <Form.Item label="Grupo" name="groupId">
                      <Input defaultValue={group.name} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <FormFinish sending={sending} />
            </Form>
          ) : (
            <Card title="Informações">
              <Skeleton active />
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { fetchGroup, createSubGroup })(NewScreen);
