import styled from 'styled-components';

interface IPropsStyle {
  foreword?: any;
}

export const Title = styled.h3<IPropsStyle>`
  letter-spacing: 2px;
  font-weight: 500;
  position: relative;
  font-size: 24px;
  color: #4174b4;
  font-family: 'Montserrat';
  margin-bottom: 0;
  margin-top: 10px;

  &:before {
    content: 'Titulo';
    content: '${(props) => props.foreword}';
    display: block;
    color: black;
    width: 100%;
    height: auto;
    opacity: 0.1;
    font-weight: 900;
    font-size: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    left: 2px;
    bottom: 10px;
  }
`;

export const Subtitle = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 1px;
`;
