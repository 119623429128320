import { Route } from 'react-router-dom';

import GroupsScreen from 'src/screens/groups/groups.component';
import GroupsNewScreen from 'src/screens/groups/new/new.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const groupsRouteGroup = (
  <Route path="grupos">
    <Route index element={<PrivateRoute children={<GroupsScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<GroupsNewScreen />} />}
    />
  </Route>
);

export default groupsRouteGroup;
