import { ISupplier } from 'src/interfaces/suppliers';

export const rules = {
  name: [{ required: true }],
  fantasyName: [{ required: true }],
  documentNumber: [{ required: true }],
  email: [{ required: true }],
  phoneNumber: [{ required: true }],
  observations: [{ required: true }],
  address: [{ required: true }],
  addressNumber: [{ required: false }],
  neighborhood: [{ required: true }],
  postalCode: [{ required: true }],
  city: [{ required: true }],
  state: [{ required: true }],
};

export const initialValues = (item?: ISupplier | any) => {
  return {
    name: item ? item.name : '',
    fantasyName: item ? item.fantasyName : '',
    documentNumber: item ? item.documentNumber : '',
    email: item ? item.email : '',
    phoneNumber: item ? item.phoneNumber : '',
    observations: item ? item.observations : '',
    address: item ? item.address : '',
    addressNumber: item ? item?.addressNumber : '',
    neighborhood: item ? item.neighborhood : '',
    postalCode: item ? item.postalCode : '',
    city: item ? item.city : '',
    state: item ? item.state : '',
  };
};
