import React from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { Row, Col, Form, Input, Divider, Select, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { Card } from 'src/components';
import { fetchInformations } from 'src/store/products/actions';
import { units } from 'src/utils';

import { rules } from '../../edit.utils';
import { IProps } from './info.type';

const { Dragger } = Upload;

const InfoComponent: React.FC<IProps> = (props) => {
  const { families, groups } = props;

  const form = Form.useFormInstance();

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    onChange(info: any) {
      form.setFieldValue('image', info.file);
    },
    onDrop(e: any) {
      return false;
    },
    onRemove: (file) => {
      form.setFieldValue('image', undefined);
    },
    beforeUpload: (file: any) => {
      form.setFieldValue('image', file);
      return false;
    },
  };

  const onChange = (name: string, value: string) => {
    form.setFieldValue(name, value);

    if (name === 'groupId') {
      props.handleGroup(value);
    }
  };

  return (
    <Card title="Informações">
      <Row gutter={16}>
        <Col span={24} lg={12}>
          <Form.Item label="Nome" name="name" rules={rules.name}>
            <Input placeholder="Nome" />
          </Form.Item>
        </Col>
        <Col span={24} lg={6}>
          <Form.Item label="Unidade" name="unit" rules={rules.unit}>
            <Select allowClear placeholder="Selecione...">
              {units.map((item: any) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={6}>
          <Form.Item label="NCM" name="ncm" rules={rules.ncm}>
            <InputMask mask="9999.99.99">
              {(inputProps: any) => <Input placeholder="NCM" {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
        <Divider orientation="left" plain className="mt-0">
          <b>Atributos</b>
        </Divider>
        <Col span={24} lg={12}>
          <Form.Item label="Família" name="familyId" rules={rules.familyId}>
            <Select
              allowClear
              disabled={!families?.length}
              showSearch
              onChange={(e) => onChange('familyId', e)}
              placeholder="Selecione..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                String(option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={families}
            />
            {!families?.length && (
              <small className="text-danger">
                Você precisa adicionar pelo menos uma família ao sistema para
                continuar
              </small>
            )}
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item label="Grupo" name="groupId" rules={rules.groupId}>
            <Select
              allowClear
              disabled={!groups?.length}
              showSearch
              onChange={(e) => onChange('groupId', e)}
              placeholder="Selecione..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                String(option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={groups}
            />
            {!groups?.length && (
              <small className="text-danger">
                Você precisa adicionar pelo menos um grupo ao sistema para
                continuar
              </small>
            )}
          </Form.Item>
        </Col>
        <Divider orientation="left" plain className="mt-0">
          <b>Imagem</b>
        </Divider>
        <Col span={8} className="mb-3">
          <Form.Item name="image" rules={rules.image}>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon mb-2">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste o arquivo para esta área para fazer o upload
              </p>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(undefined, {
  fetchInformations,
})(InfoComponent);
