import { IColor, IPaginateColors } from 'src/interfaces/colors';

export const FETCH_COLORS = 'FETCH_COLORS';
export const FETCH_COLOR = 'FETCH_COLOR';

export interface IReducerColorState {
  all?: IPaginateColors;
  current?: IColor;
}

export interface IFetchColors {
  type: typeof FETCH_COLORS;
  payload: IPaginateColors;
}

export interface IFetchColor {
  type: typeof FETCH_COLOR;
  payload: IColor;
}

export type ColorActionTypes = IFetchColors | IFetchColor;
