import { ISubGroup, IPaginateSubGroups } from 'src/interfaces/sub-groups';

export const FETCH_SUB_GROUPS = 'FETCH_SUB_GROUPS';
export const FETCH_SUB_GROUP = 'FETCH_SUB_GROUP';

export interface IReducerSubGroupState {
  all?: IPaginateSubGroups;
  current?: ISubGroup;
}

export interface IFetchSubGroups {
  type: typeof FETCH_SUB_GROUPS;
  payload: IPaginateSubGroups;
}

export interface IFetchSubGroup {
  type: typeof FETCH_SUB_GROUP;
  payload: ISubGroup;
}

export type SubGroupActionTypes = IFetchSubGroups | IFetchSubGroup;
