import { api } from './';

import {
  ICreateFamilyInput,
  IUpdateFamilyInput,
} from 'src/interfaces/families';

const FamilyService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/families', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/families/${id}`);
    return res.data;
  },
  create: async (input: ICreateFamilyInput) => {
    const res = await api.post('/families', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateFamilyInput) => {
    const res = await api.put(`/families/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/families/${id}`);
    return res.data;
  },
};

export default FamilyService;
