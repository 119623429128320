import { api } from './';

import { ICreateSubGroupInput, IUpdateSubGroupInput } from 'src/interfaces/sub-groups';

const SubGroupsService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/sub_groups', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/sub_groups/${id}`);
    return res.data;
  },
  create: async (input: ICreateSubGroupInput) => {
    const res = await api.post('/sub_groups', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateSubGroupInput) => {
    const res = await api.put(`/sub_groups/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/sub_groups/${id}`);
    return res.data;
  },
};

export default SubGroupsService;
