import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerGroupState,
  FETCH_GROUP,
  FETCH_GROUPS,
  GroupActionTypes,
} from './types';

const initialState: IReducerGroupState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'groups',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: GroupActionTypes) => {
  switch (action.type) {
    case FETCH_GROUPS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_GROUP:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
