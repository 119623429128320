import { api } from './';

import {
  ICreateProductInput,
  IUpdateProductInput,
} from 'src/interfaces/products';

const ProductsService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/products', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  info: async () => {
    const res = await api.get('/informations');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/products/${id}`);
    return res.data;
  },
  create: async (input: ICreateProductInput) => {
    const res = await api.post('/products', input, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res.data;
  },
  update: async (id: string, input: IUpdateProductInput) => {
    const res = await api.put(`/products/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/products/${id}`);
    return res.data;
  },
};

export default ProductsService;
