import { Route } from 'react-router-dom';

import FamiliesScreen from 'src/screens/families/families.component';
import FamiliesNewScreen from 'src/screens/families/new/new.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const familyRouteGroup = (
  <Route path="familias">
    <Route index element={<PrivateRoute children={<FamiliesScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<FamiliesNewScreen />} />}
    />
  </Route>
);

export default familyRouteGroup;
