import { api } from './';

import { ICreateColorInput, IUpdateColorInput } from 'src/interfaces/colors';

const ColorsService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/colors', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/colors/${id}`);
    return res.data;
  },
  create: async (input: ICreateColorInput) => {
    const res = await api.post('/colors', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateColorInput) => {
    const res = await api.put(`/colors/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/colors/${id}`);
    return res.data;
  },
};

export default ColorsService;
