import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Skeleton } from 'antd';

import { Container, Card, Title, GoBack, FormFinish } from 'src/components';
import {
  createProduct,
  fetchInformations,
  fetchGroup,
} from 'src/store/products/actions';

import InfoComponent from './components/info/info.component';
import VariantComponent from './components/variant/variant.component';

import { IProps } from './new.type';
import useProducts from './new.use';

const NewScreen: React.FC<IProps> = (props) => {
  useProducts(props);

  const {
    form,
    loading,
    sending,
    handleSubmit,
    colors,
    groups,
    families,
    subGroups,
    handleGroup,
  } = useProducts(props);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Produtos" title="Cadastre um novo produto" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {loading ? (
            <Card>
              <Skeleton active />
            </Card>
          ) : (
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <InfoComponent
                groups={groups}
                families={families}
                handleGroup={handleGroup}
              />
              <VariantComponent colors={colors} subGroups={subGroups} />
              <FormFinish sending={sending} />
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, {
  createProduct,
  fetchInformations,
  fetchGroup,
})(NewScreen);
