import { Route } from 'react-router-dom';

import { PrivateRoute } from './user.utils';

import colorsRouteGroup from './groups/colors.routes';
import familyRouteGroup from './groups/family.routes';
import groupsRouteGroup from './groups/groups.routes';
import subGroupsRouteGroup from './groups/sub-groups.routes';
import suppliersRouteGroup from './groups/suppliers.routes';
import productsRouteGroup from './groups/products.routes';

import MainScreen from 'src/screens/main/main.component';

const userRoutes = (
  <Route path="painel">
    <Route index element={<PrivateRoute children={<MainScreen />} />} />
    {colorsRouteGroup}
    {familyRouteGroup}
    {groupsRouteGroup}
    {subGroupsRouteGroup}
    {suppliersRouteGroup}
    {productsRouteGroup}
  </Route>
);

export default userRoutes;
