import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { IFamily } from 'src/interfaces/families';
import { IGroup } from 'src/interfaces/groups';
import { ISubGroup } from 'src/interfaces/sub-groups';
import { IColor } from 'src/interfaces/colors';

import { Notification } from 'src/utils';
import { IUpdateProductInput, IProduct } from 'src/interfaces/products';
import { IProps, IParams } from './edit.type';

const useProducts = (props: IProps) => {
  const navigate = useNavigate();

  const { id } = useParams<IParams | any>();

  const [form] = Form.useForm();

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<[DefaultOptionType] | []>([]);
  const [subGroups, setSubGroups] = useState<[DefaultOptionType] | []>([]);
  const [families, setFamilies] = useState<[DefaultOptionType] | []>([]);
  const [colors, setColors] = useState<[DefaultOptionType] | []>([]);

  const [item, setItem] = useState<IProduct | undefined>(undefined);

  useEffect(() => {
    handleProduct(id);
    handleInformations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProduct = async (id?: string) => {
    setLoading(true);

    if (!!id) {
      try {
        const data: IProduct = await props.fetchProduct(id);
        setItem(data);
      } catch (err: any) {
        Notification.error({
          title: 'Oops!',
          message: 'Desculpe, não encontramos o produto desejado',
        });

        navigate('/painel/produtos');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);

      Notification.error({
        title: 'Oops!',
        message: 'Desculpe, não encontramos o produto desejado',
      });
    }
  };

  const handleInformations = async () => {
    setLoading(true);

    try {
      const res = await props.fetchInformations();

      const groups: [DefaultOptionType] | any = res.groups?.map(
        (item: IGroup) => ({
          value: item.id,
          label: item.name,
        })
      );

      const families: [DefaultOptionType] | any = res.families?.map(
        (item: IFamily) => ({
          value: item.id,
          label: item.name,
        })
      );

      const colors: [DefaultOptionType] | any = res.colors?.map(
        (item: IColor) => ({
          value: item.id,
          label: item.name,
        })
      );

      setGroups(groups);
      setFamilies(families);
      setColors(colors);
    } catch (err: any) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleGroup = async (id: string) => {
    try {
      const res = await props.fetchGroup(id);

      const options: [DefaultOptionType] | any = res.subGroups?.map(
        (item: ISubGroup) => ({
          value: item.id,
          label: item.name,
        })
      );

      setSubGroups(options);
    } catch (err: any) {
      return false;
    }
  };

  const handleSubmit = async (input: IUpdateProductInput) => {
    setSending(true);

    const formData = new FormData();

    formData.append('name', input.name);
    formData.append('groupId', String(input.groupId));
    formData.append('familyId', String(input.familyId));
    formData.append('ncm', input.ncm);
    formData.append('unit', input.unit);

    if (input.image) {
      formData.append('image', input.image);
    }

    if (!!id) {
      try {
        const data = await props.updateProduct(id, formData);

        if (!!data) {
          Notification.success({
            title: 'Ok',
            message: 'Produto editado com sucesso!',
          });

          navigate(-1);
        }
      } catch (err: any) {
        Notification.error({
          title: 'Oops!',
          message:
            'Ocorreu um erro ao atualizar esse produto, tente novamente mais tarde.',
        });
        return false;
      } finally {
        setSending(false);
      }
    } else {
      setSending(false);

      Notification.error({
        title: 'Oops!',
        message:
          'Ocorreu um erro ao atualizar esse produto, tente novamente mais tarde.',
      });
    }
  };

  return {
    form,
    loading,
    families,
    groups,
    colors,
    subGroups,
    handleGroup,
    handleSubmit,
    sending,
    item,
  };
};

export default useProducts;
