import { IGroup, IPaginateGroups } from 'src/interfaces/groups';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_GROUP = 'FETCH_GROUP';

export interface IReducerGroupState {
  all?: IPaginateGroups;
  current?: IGroup;
}

export interface IFetchGroups {
  type: typeof FETCH_GROUPS;
  payload: IPaginateGroups;
}

export interface IFetchGroup {
  type: typeof FETCH_GROUP;
  payload: IGroup;
}

export type GroupActionTypes = IFetchGroups | IFetchGroup;
