import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'antd';

import { Container, Title, GoBack, FormFinish } from 'src/components';
import { createSupplier } from 'src/store/suppliers/actions';

import ContactComponent from './components/contact/contact.component';
import InfoComponent from './components/info/info.component';
import AddressComponent from './components/address/address.component';

import { IProps } from './new.type';
import useSuppliers from './new.use';

const NewScreen: React.FC<IProps> = (props) => {
  const { sending, handleSubmit } = useSuppliers(props);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title foreword="Fornecedores" title="Cadastre um novo fornecedor" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <InfoComponent />
            <ContactComponent />
            <AddressComponent />
            <FormFinish sending={sending} />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { createSupplier })(NewScreen);
