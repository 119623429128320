import { IProduct } from 'src/interfaces/products';

export const rules = {
  name: [{ required: true }],
  unit: [{ required: true }],
  ncm: [{ required: true }],
  groupId: [{ required: true }],
  familyId: [{ required: true }],
  image: [{ required: true }],
  uuid: [{ required: false }],
  colorId: [{ required: false }],
  subGroupId: [{ required: false }],
};

export const initialValues = (item?: IProduct | any) => {
  return {
    name: item ? item.name : '',
    unit: item ? item.unit : '',
    ncm: item ? item.ncm : '',
    groupId: item ? item.groupId : '',
    familyId: item ? item.familyId : '',
    image: item ? item.image : '',
  };
};
