import { api } from './';

import {
  ICreateSupplierInput,
  IUpdateSupplierInput,
} from 'src/interfaces/suppliers';

const SuppliersService = {
  all: async (pageNumber: number) => {
    const res = await api.get('/suppliers', {
      params: {
        page: pageNumber,
      },
    });
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/suppliers/${id}`);
    return res.data;
  },
  create: async (input: ICreateSupplierInput) => {
    const res = await api.post('/suppliers', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateSupplierInput) => {
    const res = await api.put(`/suppliers/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/suppliers/${id}`);
    return res.data;
  },
};

export default SuppliersService;
