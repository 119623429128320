import { useState } from 'react';
import { Form } from 'antd';

import { IProps } from './variant.type';
import { useDispatch } from 'react-redux';
import { UPDATE_VARIANT_TO_TABLE } from 'src/store/products/types';

interface DataType {
  key: number;
  color: any;
  subGroup: any;
}

const useProduct = (props: IProps) => {
  const { colors, subGroups } = props;

  const dispatch = useDispatch();
  const form = Form.useFormInstance();

  const [count, setCount] = useState(2);
  const [dataSource, setDataSource] = useState<any>([]);

  const handleAdd = (value: any) => {
    const color = colors.find((item: any) => item.value === value.colorId);
    const subGroup = subGroups.find(
      (item: any) => item.value === value.subGroupId
    );

    if (color && subGroup) {
      const newData: DataType = {
        key: count,
        color: color,
        subGroup: subGroup,
      };

      setDataSource([...dataSource, newData]);
      setCount(count + 1);
      dispatch({
        type: UPDATE_VARIANT_TO_TABLE,
        payload: [...dataSource, newData],
      });
    }
  };

  const handleDelete = (key: React.Key) => {
    const data = dataSource.filter((item: any) => item.key !== key);
    setDataSource(data);

    dispatch({ type: UPDATE_VARIANT_TO_TABLE, payload: data });
  };

  return {
    dataSource,
    handleAdd,
    handleDelete,
  };
};

export default useProduct;
