import { Dispatch } from 'react';

import SubGroupsService from 'src/services/sub-groups.service';

import {
  ISubGroup,
  ICreateSubGroupInput,
  IUpdateSubGroupInput,
  IPaginateSubGroups,
} from 'src/interfaces/sub-groups';

import { request } from 'src/utils';

import {
  FETCH_SUB_GROUPS,
  FETCH_SUB_GROUP,
  SubGroupActionTypes,
} from './types';

export const fetchSubGroups =
  (pageNumber: number) => async (dispatch: Dispatch<SubGroupActionTypes>) => {
    try {
      const response: IPaginateSubGroups = await SubGroupsService.all(
        pageNumber
      );

      dispatch({
        type: FETCH_SUB_GROUPS,
        payload: response,
      });

      return response;
    } catch (err) {
      console.dir(err);
      request.errors(err);
    }
  };

export const fetchSubGroup =
  (id: string) => async (dispatch: Dispatch<SubGroupActionTypes>) => {
    try {
      const response: ISubGroup = await SubGroupsService.current(id);

      dispatch({
        type: FETCH_SUB_GROUP,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createSubGroup = (input: ICreateSubGroupInput) => async () => {
  try {
    const response: ISubGroup = await SubGroupsService.create(input);

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateSubGroup =
  (id: string, input: IUpdateSubGroupInput) => async () => {
    try {
      const response: ISubGroup = await SubGroupsService.update(id, input);

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const removeSubGroup = (id: string) => async () => {
  try {
    const response: any = await SubGroupsService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
